<template>
    <div class="add-task-box">
        <el-form  v-loading="loading" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="名称" required prop="name">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="编号" required prop="num">
                <el-input v-model="form.num" placeholder="请输入编号"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                 <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">添加</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        wid:{
            type:String,
            value:""
        }
    },
    data(){
        return {
            loading:false,
            form:{
                name:"",
                num:new Date().getTime(),
                remark:""
            },
            rules:{
                name: [
                    { required: true, message: '请输入仓库名称', trigger: 'blur' }
                ],
                num: [
                    { required: true, message: '请输入仓库编号', trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注内容', trigger: 'blur' },
                    { min: 2, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
                ]
            }
        }
    },
     watch:{
        wid:{
            immediate: true,
            handler(wid){
                //重置表单
                //this.$refs['form'].resetFields()
                if(wid!=""){
                    this.getWareHouse()
                }
            },
            deep:true
        }
    },
    methods:{
        getWareHouse(){
            if(this.wid==''){
                return
            }
            let params = {
                method:"getWareHouseById",
                wid:this.wid
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                        this.loading = false
                        if(data&&data[0]){
                            console.log(data)
                            this.form = data[0]
                        }
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        onSubmit(){
            //提交
            this.$refs['form'].validate((valid) => {
                //console.log(valid)
                if(valid){
                    this.form.method = this.wid&&this.wid!=''?'updateWarehouse':"addWarehouse"
                    this.loading = true
                    console.log(this.form)
                    this.$ajax.post(this.API.api,this.form).then(({msg})=>{
                        this.loading = false
                        this.$message({
                            message: msg||this.CONST.SUC_ADD,
                            type:'success'
                        })
                        this.form.name = ""
                        this.$emit('addSuc')
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }
            })
        }
    }
}
</script>