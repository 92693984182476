<template>
        <div class="client-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>库存管理</el-breadcrumb-item>
            <el-breadcrumb-item>仓库列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button round @click="add" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item label="仓库">
                     <el-input v-model="form.warehouse"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                    prop="num"
                    label="编号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="editRule"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-edit" @click="editItem(scope.row.wid)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.wid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- content-end -->
        <!-- 分页 -->
        <div class="block">
            <el-pagination
                 @current-change="changePage"
                layout="prev, pager, next"
                :page-size="pageSize"
                :total="totalPage">
            </el-pagination>
        </div>
        <!-- 添加仓库 -->
        <el-dialog title="添加仓库" 
        :before-close="closeAddWarehouse"
        :visible.sync="drawer">
            <AddWarehouse ref="addWarehouse" @addSuc="addSuc" :wid="currentWid"/>
        </el-dialog>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import {exportExcel} from '@/utils/excel.js'
import AddWarehouse from '@/components/AddWarehouse'
export default {
    components:{
        AddWarehouse
    },
    data(){
        return{
            currentWid:'',//要修改的wid
            addRule:checkRules(11),
            editRule:checkRules(0),
            drawer:false,
            list:[],
            loading:false,
            page:1,
            totalPage:0,
            pageSize:10,
            form:{
                warehouse:""
            }
        }
    },
    created(){
        this.searchWarehouse()
    },
    methods:{
        //导出数据
        download(){
            //console.log(this.list)
            let columnTitle=['仓库编号','仓库名称','备注']
            let columnData = []
            this.list.forEach(item=>{
                columnData.push([item.num,item.name,item.remark])
            })
            exportExcel(columnTitle, columnData, "仓库列表");
        },
        //关闭弹窗
        closeAddWarehouse(cb){
            //this.$refs['addWarehouse'].close(cb)
            this.currentWid=''
            return cb()
        },
        editItem(wid){
            this.drawer = true 
            this.currentWid = wid
        },
        //切换页码
        changePage(page){
            //console.log(page)
            this.page = page 
            this.search()
        },
        search(){
            this.page = 1
           // console.log('搜索')
            this.searchWarehouse()
        },
        //删除
        handleDelete(wid){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                        let params = {
                            method:"delWarehouse",
                            wid
                        }
                        this.loading = true
                    this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false 
                            this.$message({
                                message: this.CONST.DEL_SUC,
                                type: 'success'
                            });
                            //刷新
                            this.search()
                    }).catch(err=>{
                        this.loading = false 
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                    })  
                }).catch(err=>{
                    this.$message({
                        type: 'info',
                        message: err.msg||this.CONST.DEL_CANCEL
                    });      
                })
        },
        //添加
        add(){
            this.drawer = true
        },
        addSuc(){
            this.drawer = false
            this.searchWarehouse()
        },
        //搜索仓库
        searchWarehouse(){
            let form = this.form
            form.method = "getWarehouse"
            form.page = this.page 
            form.pageSize = this.pageSize
            console.log(form)
            this.loading = true
            this.$ajax.post(this.API.api,form).then(res=>{
                this.loading = false 
                if(res.data){
                    this.list = res.data.list
                    this.totalPage = res.data.totalPage
                }

            }).catch(err=>{
                console.log(err.data)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //选择仓库
        select(){

        }
    }
}
</script>
<style lang="less">

</style>